:global(.k-calendar-navigation-highlight) {
      margin-top: 0 !important;
}

:global(.k-calendar-container) {
  width: 100%;
  max-width: 375px;
}

.sourceDocsContainer {
  .inputText {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid grey;
    font-size: 14px;
  }

  .dropDownList {
    width: 100%;
    border: 1px solid;
    border-radius: 5px;

    & > span {
      & > span {
        height: 36px;
      }
    }
  }

  .labelText {
    word-break: break-word;
  }
}

.customPopup {
  :global(.k-calendar-navigation-highlight) {
      margin-top: 0;
  }
}


.root {
  width: 100%
}
