$sidebar-bg-color: #0c1e35;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles.scss';
.pro-sidebar {
    min-width: 220px;
    width: 220px;
    height:auto !important;
    color:#3D234D;
    font-weight: 300;
    font-size: 18px;
    line-height: 17px;
}
.pro-sidebar-content {
  background-color:#ECE3F2;

  .avatar {
    background: #F8F5FA !important;
    border: 1px solid #D5C0E2 !important ;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #B1ADB4 !important;
  }
}
.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 16px;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  outline: none;
  color: inherit;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  outline: none;
  color: inherit;
}
.pro-sidebar .pro-menu {
  padding-top: 0px;
}
.pro-sidebar-content > nav > ul > li.pro-menu-item.pro-sub-menu.open > div.pro-inner-item{
  background-color:#F8F5FA;
  font-weight: 500;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    background-color: #FDFDFD;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding: 8px 35px 8px 8px;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    white-space: normal;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu.open > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    transform: rotate(-135deg);
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    transform: rotate(45deg);
}
.pro-sidebar .pro-menu .pro-menu-item.active {
  list-style-type: disc;
  color: #754493;
  margin-left: 20px;
  font-weight: 400;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 8px 30px 8px 0px;
}
.pro-sidebar > .pro-sidebar-inner {
    background:white;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom : none;
}

.MuiToolbar-root > div {
  align-items: center;
}

#root {
  height: 100vh;
}

.app {
  height: 100%;
  display: flex;
  position: relative;
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .social-bagdes {
    margin-top: 10px;
    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }
    .block {
      direction: rtl;
      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}
.topNavbar {
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
}

.topNavbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topNavbar a:hover {
  background: #ddd;
  color: black;
}
.MuiBreadcrumbs-li{
  color: black;
  cursor: pointer;
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.MuiBreadcrumbs-li:last-of-type{
  color: #419383;
}
.navIcon {
  position: static;
  width: 28px;
  height: 28px;
  left: 16px;
  top: 11px;

  /* Light theme/Default saturation/Praveen/Primary button enabled */

  background: linear-gradient(180deg, #8279C2 24.48%, rgba(76, 66, 147, 0.83) 100%, rgba(76, 66, 147, 0.83) 100%);
  border-radius: 24px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
  color:white;
}
.sideBarButtonIcon{
  filter: invert(100%) sepia(0%) saturate(7491%) hue-rotate(301deg) brightness(103%) contrast(104%)

}

// .navIcon:hover .sideBarButtonIcon {
//   filter: invert(28%) sepia(57%) saturate(670%) hue-rotate(234deg) brightness(98%) contrast(87%);

// }
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
  background-color: white;
  padding-top: 20px;
}
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover .pro-icon-wrapper .pro-icon {
  animation: none;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover .pro-icon-wrapper .pro-icon {
  animation: none;
}