
.importtfn-title {
  p {
    color: #928c95;
    margin: 0;
  }
}
.dnd-wrapper {
  // width: 75%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 450px;
  margin-top: 20px;
  // width: 96.5%;
  .uploading {
    font-size: 24px;
  }

  .dnd-title {
    margin-top: 50px;
    width: 300px;
    height: 87px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-bottom: 30px;
  }
  div {
    text-align: center;
  }
}


.dialog-import-tfn {
  font-size: 16px;
  // width: 550px;
  background: white;

  .content {
    width: 100%;
    h2 {
      margin: 0;
      background: #d3231c;
      padding: 20px 30px;
      color: white;
      display: flex;
      align-items: center;
      font-size: 1.65rem;
      svg{
        margin-right: 10px;
      }
    }
    li {
      list-style: none;
      padding: 8px 25px;
    }
  }
  .actions {
    width: 100%;
    margin: auto;
    text-align: right;
    cursor: pointer;
    padding-top: 30px;
  }
  button {
    border: 1px solid #754493;
    margin: 0 5px;
    color: white;
    background: linear-gradient(
      180deg,
      #8279c2 24.48%,
      rgba(76, 66, 147, 0.83) 100%,
      rgba(76, 66, 147, 0.83) 100%
    );
    margin-bottom: 30px;
    border-radius: 6px;
    padding: 8px 25px;
    width: 96px;
    margin-right: 30px;
  }
}

.input-hide {
  display: none;
}

.uploadfile-button {
  width: 137px;
  height: 40px;
  background: linear-gradient(
    180deg,
    #8279c2 24.48%,
    rgba(76, 66, 147, 0.83) 100%,
    rgba(76, 66, 147, 0.83) 100%
  );
  border-radius: 8px;
  color: white;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.importTfn-dialog .MuiPaper-root {
  padding: 0;
  .import-dialog-button{
    border: 1px solid #754493;
    margin: 0 5px;
    border-radius: 7px;
    padding: 8px 25px;
    text-align: right;
    background: #754493;
    color: white;
  }
}

.importtfn-title{
  h1{
    margin: 0;
    // font-weight: 400;
  }
}