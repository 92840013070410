.dialogInfo {
  font-size: 16px;
  background: white;

  .info {
    background-color: #f8f5fa;
    color: #8279c2;
  }
  h2 {
    margin: 0;
    background: #d3231c;
    padding: 10px 30px;
    color: white;
    display: flex;
    align-items: center;
    font-size: 1.65rem;
    svg{
      margin-right: 10px;
    }
  }
  li {
    list-style: none;
    padding: 8px 25px;
  }
  .actions {
    width: 100%;
    margin: auto;
    text-align: right;
    cursor: pointer;
    padding-top: 30px;
  }
}
