.login {
  background-color: white;
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  background-image: url("../../assets/logo-heart.svg");
  background-size: cover;

  .hide.login {
    display: none;
  }

  .cred-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 64px;
    background: white;
    height: 100vh;
    max-width: 600px;

    input#email,
    input#password {
      width: 450px;
      height: 48px;
      padding-left: 10px;
      margin-top: 5px;
      margin-bottom: 10px;
      border-radius: 5px;
      border-width: 0.1px;
      font-size: 16px;
      border: 0.5px solid #a0a1a4;
    }

    .password-reset {
      margin-top: 10px;
      a {
        color: #754493;
        text-decoration: none;
      }
    }
    .password-reset {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #8F8A92;
    }

    .legal {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;

      .links {
        flex-direction: row;
        cursor: pointer;
      }
    }
    h2 {
      color: #754493;
      margin-bottom: 18px;
      font-size: 18px;
      font-weight: 400;
    }
    h1 {
      margin: 0;
      margin-bottom: 43px;
      font-size: 36px;
      font-weight: 400;
      color: #130B18;
    }

    .submitContainer {
      margin-bottom: 50px;
      #rememberMe {
        float: left;
        margin-left: 0;
        label {
          padding-left: 5px;
        }
      }

      .login-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        color: white;
        position: static;
        width: 450px;
        height: 45px;
        cursor: pointer;
        background: linear-gradient(180deg, #8279C2 24.48%, rgba(76, 66, 147, 0.83) 100%, rgba(76, 66, 147, 0.83) 100%);
        border-radius: 8px;
        font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        font-size: 16px;
        /* Inside Auto Layout */
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        margin: 48px 0px;
        margin-bottom: 15px;
        border: none;
        &:hover {
          background: linear-gradient(180deg, rgba(76, 66, 147, 0.83) 24.48%, #8279C2 100%, #8279C2 100%);
        }
        &:disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .legal p {
      font-size: 12px;
      margin-bottom: 18px;
      color: #8F8A92;
    }

    .links a {
      color: #754493;
      padding-left: 20px;
    }

    .login-error {
      padding: 16px;
      position: static;
      width: 450px;
      height: 108px;
      background: #fbe7e3;
      border: 1px solid #e03616;
      box-sizing: border-box;
      border-radius: 8px;
      margin-bottom: 9px;
      display: none;
      align-items: center;

      p {
        padding: 0px 15px;
        float: right;
        color: #cb1606;
        font-weight: 500;
        margin: 0;
      }

      svg {
        color: #cb1605 !important;
        width: 64px;
        font-size: 24px;
        height: 64px;
      }
    }

    .login-error.active {
      display: inline-flex;
      margin-top: -30px;
    }
  }
}

.channel-type {
  margin-top: 15px;

  div {
    margin-bottom: 3px;
    label {
      margin-left: 5px;
    }
  }
}

.module-selection {
  background-image: url("../../assets/logo-heart.svg");
  background-size: cover;

  > div {
    background: white;
    box-shadow: 1px 2px 10px #888888;
  }
  .verticals {
    display: flex;

    img {
      cursor: pointer;
    }
  }

  .moduleSelection-buttons {
    img {
      width: 12.5vw;
    }
  }

  .user-info {
    text-align: center;
  }

  h3 {
    color: #754493;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
  }

  h1 {
    color: #130B18;
    font-size: 36px;
    line-height: 42px;
    font-weight: 400;
    margin: 0 0 32px !important;
  }

  p {
    margin-bottom: 20px !important;
    color: #8F8A92;
  }

  .selectModule-button {
    font-size: 16px;
  }
}
