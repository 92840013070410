.required:after{
    content:'*';
    color: #D3231C;
}
.font{
  color: #130b18;
  font-size: 16px;
  font-weight: 400;
}
.productText{
  padding-left: 10px;
  width:300px;
  height:35px;
  border-radius: 5px;
  border:1px solid grey;
  font-size: 20px;
}
.inputText{
  padding-left: 10px;
  width:500px;
  height:40px;
  border-radius: 5px;
  border:1px solid grey;
  font-size: 20px;
}
.copyblock-wrapper{
  background: white;
  height: 100px;
  margin-top: 20px;
  width: 96.5%;
}
.newgeography-wrapper{
  background: white;
  height:auto 0;
  margin-top: 20px;
  width: 96.5%;
}
.new-wrapper {
  background: white;
  height: auto 0;
  margin-top: 20px;
  .uploading {
    font-size: 24px;
  }
}
.product-wrapper {
  background: white;
  height:auto ;
  margin-top: 20px;
  
  width: 96.5%;
  .uploading {
    font-size: 24px;
  }
  
  }
.inner-wrapper {
  background: white;
  height: 200px;
  margin-top: 20px;
  width: 100%;

  }
  .new-title {
    margin-top: 50px;
    width: 300px;
    height: 87px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-bottom: 30px;
   
  }
.show-form{
  height: 320px;
  margin-top: 20px;
  background-color:white;
  width: 96.5%;
}
input[type=number]::-webkit-inner-spin-button{
  opacity:1;
}
.product-data-wrapper {
  display: flex;
  flex-direction: column;
}
