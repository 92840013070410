// .k-dropdown-wrap > .k-select > .k-i-filter {
//   color: "white";
// }
.kendoGrid .k-header .k-grid-column-menu {
}
.k-grid .k-alt {
  background: transparent !important;
}
.k-grid tr {
  border-bottom: 1px solid black;
}
.kendoGrid .k-header .k-grid-column-menu .k-i-more-vertical::before {
  content: "\e129";
}
.filterApplied .k-i-more-vertical::before {
  content: "\e12a" !important;
}
table > thead > tr > th:nth-child(1) {
  border-right-color: transparent;
}
table > thead > tr > th:nth-child(2) {
  border-left-color: transparent;
}
td:nth-child(1) {
  border-right-color: transparent !important;
}
td:nth-child(2) {
  border-left-color: transparent !important;
}
.k-checkbox,
.k-radio {
  opacity: 1;
}
.dialog {
  position: "absolute";
  left: 10;
  top: 50;
}

.k-grid .k-grid-header .k-header .k-link {
  height: auto;
}

.k-column-title {
  white-space: normal !important;
}
.tabButtons {
  margin-right: 10px !important;
  border-color: #754493 !important;
  color: #754493 !important;
  border-radius: 8px !important;
  text-transform: inherit !important;
}
.tabButtonSelected {
  margin-right: 10px !important;
  background: linear-gradient(
    180deg,
    #8279c2 24.48%,
    rgba(76, 66, 147, 0.83) 100%,
    rgba(76, 66, 147, 0.83) 100%
  ) !important;
  border-radius: 8px !important;
  color: white !important;
  text-transform: inherit !important;
}
.customSelect {
  width: 100%;
  padding: 5px;
}
.disabled{
  opacity:0.5;
  pointer-events:none;
  
}
.customSelect div {
  padding: 4px 0 5px;
}
.customSelect legend {
  display: none;
}
.k-grid td {
  border-width: 0 0 1px 0px !important;
}

.importsuccess-dialog .MuiPaper-root {
  background-color: #419383;
  padding: 0;
  text-align: center;
  height: auto;

  p {
    color: white !important;
  }
}
.k-dropdown-wrap > .k-select > .k-i-filter {
  color: "white";
}
.kendoGrid .k-header .k-grid-column-menu {
}
.kendoGrid .k-header .k-grid-column-menu .k-i-more-vertical::before {
  content: "\e129";
}
.filterApplied .k-i-more-vertical::before {
  content: "\e12a" !important;
}
.headerMenu {
}
.dialog {
  position: "absolute";
  left: 10;
  top: 50;
}
.k-grid .k-alt {
  background: transparent !important;
}
.k-grid td {
  border-width: 0 0 1px 0px !important;
}
.button:hover {
  color: white !important;
  background: linear-gradient(
    180deg,
    #8279c2 24.48%,
    rgba(76, 66, 147, 0.83) 100%,
    rgba(76, 66, 147, 0.83) 100%
  ) !important;
}
.button {
  border-radius: 8px !important;
  border: 1px solid #4c4394 !important;
  box-sizing: border-box !important;
  background: #fdfdfd !important;
  color: #754493 !important;
  &.Mui-disabled {
    opacity: 0.7;
  }
}
.buttonIcon {
  filter: invert(28%) sepia(57%) saturate(670%) hue-rotate(234deg)
    brightness(98%) contrast(87%);
}
.tableButtonIcon {
  filter: invert(26%) sepia(63%) saturate(638%) hue-rotate(208deg)
    brightness(95%) contrast(94%);
  height: 18px;
}
.button:hover .buttonIcon {
  filter: invert(100%) sepia(0%) saturate(7491%) hue-rotate(301deg)
    brightness(103%) contrast(104%);
}
.tableButtonIconDisable {
  filter: invert(93%) sepia(1%) saturate(2535%) hue-rotate(162deg)
    brightness(106%) contrast(93%);
  height: 18px;
}
.k-checkbox-label:before {
  display: none;
}
.k-filter-menu .k-button {
  margin: 0;
}
.k-pager-wrap .k-link > .k-icon {
  color: #944ebe;
  border-color: #ccc;
}
.k-pager-numbers .k-link.k-state-selected,
.k-pager-numbers .k-link:link.k-state-selected {
  background-color: #d9bfe4;
  color: #333;
}
.k-pager-numbers .k-link,
.k-pager-numbers .k-link:link {
  color: #944ebe;
}
.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  background-color: #944ebe !important;
}
.k-column-title {
  color: #130b18;
  font-size: 16px;
  font-weight: 510;
}
// .k-grid tr{
//   background: #f8f5fa;
// }
.k-grid td {
  font-size: 16px;
}
.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  background-color: #f3f9f8 !important;
  color: black !important;
}
.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link.k-state-hover {
  color: #944ebe !important;
  border-color: #ccc;
  background-color: #ebebeb !important;
}

.k-cell-inner {
  background: #f8f5fa !important;
}
.k-grid td {
  background-color: #ffffff !important;
}
.k-button-primary,
.k-button.k-primary {
  background-color: #4d4395 !important;
  border-color: #4d4395 !important;
}
.k-dialog-buttongroup .k-button.k-primary {
  color: #ffffff;
}
.k-filtercell {
  display: block !important;
}
.k-button.k-primary:hover {
  background-image: none !important;
  border-color: none !important;
}
.k-grid td {
  padding: 3px 12px !important;
  word-wrap: break-word;
}
col.k-sorted,
th.k-sorted {
  background-color: transparent !important;
}
.k-checkbox {
  border-color: #4c4293 !important;
}
.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #419383 !important;
  background-color: #419383 !important;
}
.k-grid tr {
  background: #f8f5fa !important;
}
.k-grid {
  color: #130b18;
}
.overrideButton {
  background: transparent !important;
  background-color: none !important;
  border-color: transparent !important;
}
.overrideButton img {
  height: 20px;
}
.k-animation-container {
  z-index: 10003 !important;
}
.k-dialog-titlebar {
  background-color: #f8f5fa !important;
  color: black !important;
}

.advanced-filter .k-widget.k-dropdown {
  .k-input {
    height: 100%;
  }
}

.advanced-filter {
  .k-multiselect .k-multiselect-wrap .k-clear-value {
    top: 50%;
    right: -5px;
    color: black;
  }
}


.upload-shutdown-tfn-dialog .MuiPaper-root {
  padding: 0;
  .import-shutdown-tfn-button{
    border: 1px solid #754493;
    margin: 0 5px;
    border-radius: 7px;
    padding: 8px 25px;
    text-align: right;
    background: #754493;
    color: white;
  }
}

.upload-shutdown-tfn-title{
  h1{
    margin: 0;
    // font-weight: 400;
  }
}
.uploadfile-button-shutdown {
  width: 190px;
  height: 40px;
  background: linear-gradient(
    180deg,
    #8279c2 24.48%,
    rgba(76, 66, 147, 0.83) 100%,
    rgba(76, 66, 147, 0.83) 100%
  );
  border-radius: 8px;
  color: white;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}


.dialog-shutdown-tfn {
  font-size: 16px;
  // width: 550px;
  background: white;

  .content {
    width: 100%;
    h2 {
      margin: 0;
      background: #d3231c;
      padding: 20px 30px;
      color: white;
      display: flex;
      align-items: center;
      font-size: 1.65rem;
      svg{
        margin-right: 10px;
      }
    }
    li {
      list-style: none;
      padding: 8px 25px;
    }
  }
  .actions {
    width: 100%;
    margin: auto;
    text-align: right;
    cursor: pointer;
    padding-top: 30px;
  }
  button {
    border: 1px solid #754493;
    margin: 0 5px;
    color: white;
    background: linear-gradient(
      180deg,
      #8279c2 24.48%,
      rgba(76, 66, 147, 0.83) 100%,
      rgba(76, 66, 147, 0.83) 100%
    );
    margin-bottom: 30px;
    border-radius: 6px;
    padding: 8px 25px;
    width: 96px;
    margin-right: 30px;
  }
}
