.rule-container {
    display: flex;

    .ref-text {
        width: 15%;
        padding: 0 1.875rem 0 0.625rem;
        border-right: 1px solid #EEEBF1;

        .ref-label {
            line-height: 40px;
            background-color: #ECE3F2;
            padding-left: 1rem;
            border-radius: 0.5rem 0 0 0.5rem;
            font-size: 1rem;
            font-weight: 500;
        }
    }
    .rule-content {
        flex: 1;
        padding: 0 1.875rem;
    }
}
