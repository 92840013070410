.navbar {
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: white;
}
  
.navbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.navbar a:hover {
  background: #ddd;
  color: black;
}

.main {
  padding: 16px 64px;
  // margin-top: 80px;
  margin-top: 52px;
  // padding-right: 64px;
}

.ub-just-cnt_center.ub-z-idx_20 {
  z-index: 1201;
}

.ub-max-h_cl1z91.ub-flx-drct_column {
  max-height: none;
}
.admin-contact{
  display: flex;
  position: relative;
  height:10rem;
  span{
    margin: auto;
    padding: auto;
    font-size: 1.5rem;
  }
}